<template>
  <v-app id="create-client">
    <v-sheet height="100%" class="grey lighten-4 pb-10">
      <div class="ma-6">
        <v-btn class="close_btn_custom" icon to="/services">
          <v-icon color="black" large>mdi-close</v-icon>
        </v-btn>

        <v-btn
          absolute
          right
          x-large
          color="#101928"
          dark
          height="48px"
          width="160px"
          class="mr-2 text-capitalize"
          style="letter-spacing: 0.5px"
        >
          Save
        </v-btn>
      </div>

      <div class="d-flex justify-center">
        <h2 style="font-size: 1.7rem; letter-spacing: 0.6px">
          Add new service
        </h2>
      </div>

      <v-sheet
        max-width="1000"
        class="mx-auto pt-8"
        color="transparent"
        elevation="0"
        style="padding-left: 127px"
      >
        <v-row>
          <v-col cols="11">
            <v-card class="rounded-lg pa-">
              <h3 class="py-4 px-6">Basic info</h3>
              <p class="basic-info">
                Add a service name and choose the treatment type.
              </p>
              <v-divider></v-divider>
              <v-card-text class="px-6">
                <div class="d-flex flex-column">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <label class="dialog_label">Service name</label>
                      <v-text-field solo class="mt-1"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" class="pt-0">
                      <label class="dialog_label">Treatment type</label>
                      <v-select
                        :items="genders_list"
                        solo
                        class="mt-1"
                        placeholder="Select Treatment Type"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="12" class="pt-0">
                      <p class="treatment-type">
                        Choose the most relevant treatment type. This won't be
                        visible to your clients.
                      </p>
                    </v-col>

                    <v-col cols="12" sm="12" class="pt-0">
                      <label class="dialog_label">Service category</label>
                      <v-select
                        :items="genders_list"
                        solo
                        class="mt-1"
                        placeholder="Select an option"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="12" class="pt-0">
                      <label class="dialog_label">Service description</label>
                      <v-textarea
                        solo
                        auto-grow
                        rows="4"
                        row-height="27"
                        class="mt-1"
                        placeholder="Add a short description"
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12" sm="12" class="pt-0">
                      <label class="dialog_label">Aftercare description</label>
                      <v-textarea
                        solo
                        auto-grow
                        rows="4"
                        row-height="27"
                        class="mt-1"
                        placeholder="Add aftercare description"
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12" sm="12" class="pt-0">
                      <label class="dialog_label">Service available for</label>
                      <v-select
                        :items="genders_list"
                        solo
                        class="mt-1"
                        placeholder="Select an option"
                      ></v-select>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>

            <v-card class="rounded-lg mt-7">
              <h3 class="py-4 px-6">Online booking</h3>
              <p class="basic-info">
                Enable online bookings, choose who the service is available for
                and add a short description.
              </p>
              <v-divider></v-divider>
              <v-card-text class="px-6">
                <div class="d-flex flex-column">
                  <v-row>
                    <div>
                      <div class="d-flex flex-column px-6 pb-2">
                        <v-row>
                          <v-col cols="12" sm="12" class="pt-2">
                            <v-switch
                              class="custom_switch"
                              :v-model="true"
                              :value="true"
                              :input-value="true"
                              label="Enable online bookings"
                              color="#00a36d"
                              inset
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>

            <v-card class="rounded-lg mt-7">
              <h3 class="py-4 px-6">Team</h3>
              <p class="basic-info">
                Assign team members to the service and manage commission
              </p>
              <v-divider></v-divider>
              <v-card-text class="px-6">
                <div class="d-flex flex-column">
                  <v-row>
                    <v-row class="bottom-border">
                      <v-col
                        cols="5"
                        sm="5"
                        class="mt-3 ml-2 team-checkbox"
                        v-for="(item, index) in Team"
                        :key="index"
                      >
                        <v-checkbox :label="item.member"> </v-checkbox
                        ><v-avatar class="avatar">
                          <v-img :src="item.image" alt="John"></v-img>
                        </v-avatar>
                      </v-col>
                    </v-row>

                    <v-col cols="12" sm="12" class="pt-0 commission">
                      <label class="dialog_label">Team member commission</label>
                    </v-col>

                    <v-col cols="12" sm="12" class="pt-0">
                      <p class="">
                        Calculate team member commission when the service is
                        sold.
                      </p>
                    </v-col>

                    <div class="d-flex flex-column px-6 pb-2">
                      <v-row>
                        <v-col cols="12" sm="12" class="pt-0">
                          <v-switch
                            class="custom_switch"
                            :v-model="true"
                            :value="true"
                            :input-value="true"
                            label="Enable team member commission"
                            color="#00a36d"
                            inset
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </div>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>

            <v-card class="rounded-lg mt-7">
              <h3 class="py-4 px-6">Pricing and Duration</h3>
              <p class="basic-info">
                Add the pricing options and duration of the service.
              </p>
              <v-divider></v-divider>
              <v-card-text class="px-6 mt-2">
                <v-row
                  class="Pricing"
                  v-for="(item, index) in Pricing"
                  :key="index"
                >
                  <v-col cols="12" sm="12" class="pt-0">
                    <label class="dialog_label"
                      >Pricing option {{ index + 1 }}</label
                    >
                    <span
                      class="close-button"
                      v-if="Pricing.length > 1"
                      @click="remove_item('pricing', index)"
                      ><i class="fa fa-times"
                    /></span>
                  </v-col>

                  <v-col cols="12" sm="3" class="pt-0">
                    <label class="dialog_label">Duration</label>
                    <v-select
                      :items="genders_list"
                      solo
                      class="mt-1"
                      v-model="item.duration"
                      placeholder="Duration"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="3" class="pt-0">
                    <label class="dialog_label">Price type</label>
                    <v-select
                      :items="price_list"
                      solo
                      class="mt-1"
                      v-model="item.price_type"
                      placeholder="Price type"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="3" class="pt-0">
                    <label class="dialog_label">Price</label>
                    <v-text-field
                      solo
                      class="mt-1"
                      placeholder="0.00"
                      v-model="item.price"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3" class="pt-0">
                    <label class="dialog_label">Special price (optional)</label>
                    <v-text-field
                      solo
                      class="mt-1"
                      placeholder="0.00"
                      v-model="item.special_price"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="5" class="pt-0">
                    <label class="dialog_label">Pricing name (optional)</label>
                    <v-text-field
                      solo
                      placeholder="e.g. Long hair"
                      v-model="item.pricing_name"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-col cols="12" sm="12" class="pt-0 brand-link mt-5">
                  <a
                    class="brand-link"
                    href="javascript:void();"
                    @click="add_Information('pricing')"
                    >Add pricing option</a
                  >
                </v-col>
              </v-card-text>
            </v-card>

            <v-card class="rounded-lg mt-7">
              <h3 class="py-4 px-6">Sales settings</h3>
              <v-divider></v-divider>
              <v-card-text class="px-6">
                <div class="d-flex flex-column">
                  <v-row>
                    <v-col cols="12" sm="12" class="pt-0 mt-2">
                      <label class="dialog_label">Set the tax rate</label>
                    </v-col>

                    <v-col cols="12" sm="12" class="pt-0 mt-2">
                      <label class="dialog_label"
                        >Tax (Included in price)</label
                      >
                      <v-select
                        :items="genders_list"
                        solo
                        class="mt-1"
                        placeholder="Select an option"
                      ></v-select>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>
    </v-sheet>

    <v-divider></v-divider>
  </v-app>
</template>

<script>
export default {
  name: "NewService",

  data: () => ({
    genders_list: [
      "Select an option",
      "Female",
      "Male",
      "Non-binary",
      "Prefer not to say",
    ],
    price_list: ["Free", "From", "Fixed"],
    categoryList: ["Female", "Male", "Non-binary", "Prefer not to say"],
    Team: [
      { member: "Nilesh", image: "https://fakeimg.pl/250x100/" },
      { member: "Bhavesh", image: "https://fakeimg.pl/250x100/" },
    ],
    Pricing: [
      {
        duration: "",
        price_type: "",
        price: "",
        special_price: "",
        pricing_name: "",
      },
    ],
  }),
  methods: {
    /* Add Information */
    add_Information(type) {
      if (type === "pricing") {
        this.Pricing.push({
          duration: "",
          price_type: "",
          price: "",
          special_price: "",
          pricing_name: "",
        });
      }
    },

    /* Remove Information */
    remove_item(type, id) {
      if (type === "pricing") {
        if (this.Pricing.length > 1) {
          this.Pricing.splice(id, 1);
        }
      }
    },

    addnewCategory(newCategory) {
      if (newCategory !== "") {
        this.categoryList.push(this.newCategory);
        this.selectedCategory = this.newCategory;
      }
    },
  },
};
</script>
<style scoped>
.brand-link {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  color: #037aff;
  text-decoration: none !important;
}

.round-camera-btn {
  margin-top: 10px;
  border: 3px solid #cde4ff;
  width: calc(50px + (150 - 110) * ((550vw - 320px) / (1920 - 320)));
  height: calc(50px + (150 - 110) * ((550vw - 320px) / (1920 - 320)));
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cde4ff;
  color: #037aff;
  transition: background-color 300ms ease-in-out;
}

.camera-png {
  width: calc(20px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
  position: absolute;
}

.round-camera-btn input {
  width: 100% !important;
}

.round-camera-btn {
  background-position: center;
  background-size: cover;
}

input[type="file"] {
  font-size: 0;
  opacity: 0;
  height: 100%;
  display: block;
  cursor: pointer;
}

.round-camera-btn input {
  position: relative;
  z-index: 1;
}
.low-stock {
  margin-top: -35px;
}

.bottom-border {
  border-bottom: 1px solid #e7e8e9;
}
.Supplier {
  margin-top: -15px;
}
.close-button {
  margin-top: -22px;
  margin-right: 15px;
  cursor: pointer;
  justify-content: flex-end;
  display: flex;
  font-size: 22px;
  color: #da2346;
}
.Stock {
  margin-bottom: -22px;
}
.basic-info {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  margin-top: -17px;
  margin-left: 25px;
}
.treatment-type {
  margin-top: -30px;
}
.commission {
  margin-top: 30px;
}
.avatar {
  top: -59px;
  left: 39px;
}
.Pricing {
  border: 1px solid #d5d7da;
  padding-top: 15px;
  border-radius: 8px;
  background-color: #f2f2f7;
  margin-bottom: 5px;
}
</style>